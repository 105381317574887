import { reactive, toRefs, watch } from "vue";
import { debounce } from "lodash";
import { DataService } from "../config/dataService/dataService";

export default function usePartnerSearch(partnersIDs) {
  const partnerSearchState = reactive({
    partnerData: [],
    partnerValue: [],
    partnerFetching: false,
  });

  const getPartner = async (partnerId) => {
    const query = await DataService.get(
      `api/Partner/GetByPartenaireId?PartenaireId=${partnerId}`
    );
    if (query.succeeded) {
      const data = query.data.map((element) => ({
        label: element.partnerName,
        value: element.partnerId,
      }));
      partnerSearchState.partnerData = data;
    }
  };

  let lastPartnerFetchId = 0;

  const searchPartner = debounce(async (name, isPartnerOperation = false, IsPartnerSupport = false) => {
    lastPartnerFetchId += 1;
    const fetchId = lastPartnerFetchId;
    partnerSearchState.partnerData = [];
    partnerSearchState.partnerFetching = true;
    DataService.get(
      `api/Partenaire/GetPartnerShort?PartnerName=${name}&IsPartnerOperation=${isPartnerOperation ? true:""}&IsPartnerSupport=${IsPartnerSupport? true:""}`
    )
      .then((response) => response.data)
      .then((list) => {
        if (fetchId !== lastPartnerFetchId) {
          return;
        }
        const data = list.map((element) => ({
          label: element.name,
          value: element.id,
        }));
        partnerSearchState.partnerData = data;
        partnerSearchState.partnerFetching = false;
      });
  }, 300);

  watch(partnersIDs, () => {
    partnerSearchState.data = [];
    partnerSearchState.partnerFetching = false;
  });

  const { partnerData, partnerFetching } = toRefs(partnerSearchState);

  return {
    partnerData,
    getPartner,
    searchPartner,
    partnerFetching
  };
}
